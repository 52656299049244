<!--生成二衬，二维码  组件-->
<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">新增二维码</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div id="forms_div2">
      <Addqrcode></Addqrcode>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import Addqrcode from "@/views/bdstqr/addqr/addqrcode";

export default {
  components: {Header, Addqrcode},
  name: "addqr",
  data(){
    return{

    }
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
  }
}
</script>

<style scoped>
#admindiv1 {
  background-color: white;
  display: flex;
  width: 90%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}

#forms_div2 {
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 90%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>