<template>
  <div>
    <div style="display: flex;justify-content: space-between">
      <div id="yldiv1">
        <div style="text-align: center;padding-left: 50px;padding-right: 50px">
          <p style="font-size: 25px;word-wrap: break-word">
            {{ maintitle }}
          </p>
        </div>
        <div v-for="file in fileList" style="text-align: center">
          <img :src="file.url" alt="" style="width: 300px"/>
        </div>
      </div>
      <div id="ssdiv1">
        <!--        添加标题-->
        <div style="margin-bottom: 30px">
          <el-input
              v-model="maintitle"
              autosize
              type="textarea"
              placeholder="请输入标题"
          />
        </div>
        <!--        添加照片-->
        <div style="text-align: center">
          <el-upload ref="uploadDemo" name="file"
                     action="#"
                     :http-request="upimg"
                     list-type="picture-card"
                     :auto-upload="false"
                     :on-change="imgchange"
                     :on-remove="handleRemove"
                     accept="jpg,png,mp4"
          >
            <template #default>
              <i class="el-icon-plus"></i>
            </template>
          </el-upload>
        </div>
        <!--        生成二维码-->
        <div style="float: right;margin-top: 30px">
          <el-button type="info" v-if="this.$route.params.title && this.$route.params.id" @click="goback">返回</el-button>
          <el-button type="primary" @click="makeqrcode">提交并生成二维码</el-button>
        </div>
      </div>
    </div>
    <!--    二维码弹窗-->
    <div>
      <el-dialog
          v-model="dialogVisible"
          :title="titledia"
          width="60%"
          :center="true"
          @close="clearqr"
      >
        <div style="margin-left: 30%">
          <div id="qrcode" ref="qrCodeUrl"></div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ElMessage} from 'element-plus'
import QRCode from "qrcodejs2";

export default {
  name: "addqrcode",
  data() {
    return {
      maintitle: '',
      fileList: [],
      userid: '',
      flag: true,
      dialogVisible: false,
      uuid: '',
      titledia: ''
    }
  },
  created() {
    this.userid = this.GET_USERID
    if (this.$route.params.title && this.$route.params.id) {
      this.maintitle = this.$route.params.title;
    }
  },
  computed: {
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID'])
  },
  methods: {
    upimg() {
      this.fileList.forEach((item, index) => {
        this.axios.post("https://www.cr12cz.cn/czscsms/up/upload", (response) => {
          console.log(response)
          if (response.obj) {
            this.$refs.uploadDemo.clearFiles()
          }
        }, {
          file: item.raw,
          userid: this.userid
        })
      })
    },
    imgchange(file) {
      this.fileList.push(file);
    },
    handleRemove(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
          this.fileList.splice(i, 1)
        }
      }
    },
    makeqrcode() {
      if (this.$route.params.title && this.$route.params.id) {
        this.axios.post('/qrcodemain/changedel', (response) => {
          if (response.obj){
            this.uuid = this.$route.params.id;
            this.titledia = this.$route.params.title + "二维码";
            this.fileList.forEach((item, index) => {
              this.axios.post("https://www.cr12cz.cn/czscsms/up/upload", (responseup) => {
                if (responseup.obj) {
                  this.axios.post('/qrcodemain/insimg', (responseupimg) => {
                    this.flag = responseupimg.obj;
                    if (index === this.fileList.length - 1 && this.flag) {
                      this.dialogVisible = true;
                      this.maintitle = '';
                      this.fileList = [];
                      this.$refs.uploadDemo.clearFiles();
                      setTimeout(() => {
                        this.creatQrCode()
                      }, 100)
                    }
                  }, {
                    mainid: this.$route.params.id,
                    imgurl: responseup.obj,
                    imgindex: index
                  })
                }
              }, {
                file: item.raw,
                userid: this.userid,
              })
            })
          }
        },{
          id: this.$route.params.id
        })
      } else {
        if (this.maintitle !== '' && this.fileList.length > 0) {
          this.titledia = this.maintitle + "二维码";
          this.axios.post('/qrcodemain/insqrcode', (response) => {
            if (response.obj) {
              this.uuid = response.obj;
              this.fileList.forEach((item, index) => {
                this.axios.post("https://www.cr12cz.cn/czscsms/up/upload", (responseup) => {
                  if (responseup.obj) {
                    this.axios.post('/qrcodemain/insimg', (responseupimg) => {
                      this.flag = responseupimg.obj;
                      if (index === this.fileList.length - 1 && this.flag) {
                        this.dialogVisible = true;
                        this.maintitle = '';
                        this.fileList = [];
                        this.$refs.uploadDemo.clearFiles();
                        setTimeout(() => {
                          this.creatQrCode()
                        }, 100)
                      }
                    }, {
                      mainid: response.obj,
                      imgurl: responseup.obj,
                      imgindex: index
                    })
                  }
                }, {
                  file: item.raw,
                  userid: this.userid,
                })
              })
            }
          }, {
            adduser: this.GET_USER,
            qrmesage: this.maintitle,
            adduserid: this.GET_USERID
          })
        } else {
          ElMessage.error('缺少信息！')
        }
      }
    },
    creatQrCode() {
      const qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: 'https://www.cr12cz.cn/mobilsel?qrcodeid=' + this.uuid, // 需要转换为二维码的内容
        //text: 'http://182.92.228.212/czsc/#/mobilsel?qrcodeid=' + this.uuid, // 需要转换为二维码的内容
        width: 500,
        height: 500,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      });
    },
    clearqr() {
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
    },
    goback(){
      this.$router.push({
        name: 'Selqr',
        params: {
          key: '123123123'
        }
      })
    }
  }
}
</script>

<style scoped>
#yldiv1 {
  width: 30%;
  min-height: 600px;
  margin-left: 200px;
  border-radius: 10px;
  background-color: #F2F2F2
}

#ssdiv1 {
  width: 30%;
  height: 100px;
  margin-right: 120px;
}

</style>